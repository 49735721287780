import React, { useEffect, useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { useTranslation } from "react-i18next";
import { uniqueId } from "lodash";
import { useDispatch } from "react-redux";
import { Banner } from "../../util/Banner";
import buttonStyle from "../../../style/button.module.css";
import { showError, showInfo } from "../../../redux/actions/NotificationActions";


const validateExportData = (content) => {
    if (!content) return false;

    // Check for minimum content length (adjust as needed)
    if (content.length < 100) {
        console.error('Content length too short');
        return false;
    }

    
    return true;
};

const PREVIEW_PRICING_LISTS = gql`
    mutation PreviewPricingListExport(
        $ids: [UUID!]!
        $targets: [String!]!
        $exportAsCorrection: Boolean
        $marketingList: UUID
        $allocationList: UUID
        $minListIds: [UUID!]
        $minValidFrom: DateTime
        $minValidUntil: DateTime
    ) {
        previewPricingListExport(
            pricingListsIds: $ids
            targets: $targets
            exportAsCorrection: $exportAsCorrection
            marketingListId: $marketingList
            allocationListId: $allocationList
            minListIds: $minListIds
            minValidFrom: $minValidFrom
            minValidUntil: $minValidUntil
        ) {
            content
            fileName
            fileType
        }
    }
`;

const SEND_PREVIEW_EMAIL = gql`
    mutation SendPreviewEmail($pricingListId: UUID!) {
        sendPreviewEmail(
            pricingListId: $pricingListId
        ) {
            success
            message
        }
    }
`;



const NewTabComponent = () => {
    const { t } = useTranslation(["common", "table"]);
    
    // Button IDs
    const [outlook_export_button_id] = useState(() => uniqueId("outlook_export_button_"));
    const [pint_export_button_id] = useState(() => uniqueId("pint_export_button_"));
    const [csv_export_button_id] = useState(() => uniqueId("csv_export_button_"));
    const [dst_export_button_id] = useState(() => uniqueId("dat_export_button_"));
    const [use_correction] = useState(() => uniqueId("use_correction_"));
    const [min_valid_from_field] = useState(() => uniqueId("min_valid_from_"));
    const [min_valid_until_field] = useState(() => uniqueId("min_valid_until_"));

    // State variables
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [pricingLists, setPricingLists] = useState([]);
    const [minPricingLists, setMinPricingLists] = useState([]);
    const [marketingList, setMarketingList] = useState("");
    const [allocationList, setAllocationList] = useState("");
    const [exportTargets, setExportTargets] = useState([]);
    const [exportAsCorrection, setExportAsCorrection] = useState(true);
    const [canExport, setCanExport] = useState(false);
    const [hasCorrection, setHasCorrection] = useState(false);
    const [minValidFrom, setMinValidFrom] = useState("");
    const [minValidUntil, setMinValidUntil] = useState("");
    const [fileContent, setFileContent] = useState(null);


    const [isRetrying, setIsRetrying] = useState(false);
    const [retryCount, setRetryCount] = useState(0);
    const MAX_RETRIES = 3;

    const dispatch = useDispatch();
    const [sendEmail] = useMutation(SEND_PREVIEW_EMAIL);
    const [isEmailSending, setIsEmailSending] = useState(false)

    const [contentStats, setContentStats] = useState({
        totalLines: 0,
        processedLines: 0
    });

    const processContent = (content) => {
        if (!content) return null;
        
        const lines = content.split('\n');
        setContentStats({
            totalLines: lines.length,
            processedLines: lines.length
        });
        
        return lines;
    };

    const SynchronizedPreview = ({ content }) => {
        const [lineNumbersRef, setLineNumbersRef] = useState(null);
        const [contentRef, setContentRef] = useState(null);
    
        useEffect(() => {
            if (contentRef && lineNumbersRef) {
                const handleContentScroll = () => {
                    lineNumbersRef.scrollTop = contentRef.scrollTop;
                };
    
                contentRef.addEventListener('scroll', handleContentScroll);
                return () => contentRef.removeEventListener('scroll', handleContentScroll);
            }
        }, [contentRef, lineNumbersRef]);
    
        const lines = content.split('\n');
    
        return (
            <div style={{
                border: '1px solid #e1e4e8',
                borderRadius: '6px',
                overflow: 'hidden',
                backgroundColor: '#ffffff',
                fontFamily: 'Consolas, Monaco, "Courier New", monospace',
            }}>
                {/* IDE Header */}
                <div style={{
                    padding: '8px 12px',
                    backgroundColor: '#f6f8fa',
                    borderBottom: '1px solid #e1e4e8',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    fontSize: '12px',
                    color: '#586069'
                }}>
                    <div>{lines.length} lines</div>
                    <div>{fileContent.fileName}</div>
                </div>
    
                {/* Code Container */}
                <div style={{
                    display: 'flex',
                    maxHeight: '500px',
                }}>
                    {/* Line Numbers */}
                    <div
                        ref={setLineNumbersRef}
                        style={{
                            backgroundColor: '#f6f8fa',
                            borderRight: '1px solid #e1e4e8',
                            padding: '10px 0',
                            overflow: 'hidden',
                            userSelect: 'none',
                            textAlign: 'right',
                            color: '#586069',
                            fontSize: '12px',
                            minWidth: '40px'
                        }}
                    >
                        {lines.map((_, idx) => (
                            <div 
                                key={idx}
                                style={{
                                    padding: '0 10px',
                                    height: '20px',
                                    lineHeight: '20px'
                                }}
                            >
                                {idx + 1}
                            </div>
                        ))}
                    </div>
    
                    {/* Content */}
                    <pre
                        ref={setContentRef}
                        style={{
                            margin: 0,
                            padding: '10px 0',
                            overflow: 'auto',
                            flex: 1,
                            fontSize: '12px',
                            lineHeight: '20px',
                            backgroundColor: '#ffffff'
                        }}
                    >
                        {lines.map((line, idx) => (
                            <div 
                                key={idx}
                                style={{
                                    padding: '0 10px',
                                    height: '20px',
                                    whiteSpace: 'pre',
                                    backgroundColor: idx % 2 === 0 ? '#ffffff' : '#f6f8fa'
                                }}
                            >
                                {line}
                            </div>
                        ))}
                    </pre>
                </div>
            </div>
        );
    };


    const loaderStyles = {
        loader: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
            padding: '40px'
        },
        spinner: {
            width: '50px',
            height: '50px',
            border: '5px solid #f3f3f3',
            borderTop: '5px solid #3498db',
            borderRadius: '50%',
            animation: 'spin 1s linear infinite'
        }
    };

    const previewStyles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            border: '1px solid #e1e4e8',
            borderRadius: '6px',
            overflow: 'hidden',
            backgroundColor: '#ffffff',
            marginBottom: '20px'
        },
        header: {
            padding: '8px 12px',
            backgroundColor: '#f6f8fa',
            borderBottom: '1px solid #e1e4e8',
            fontSize: '12px',
            color: '#586069'
        },
        content: {
            display: 'flex',
            maxHeight: '500px',
            overflow: 'hidden'
        }
    };

    const styles = {
        contentContainer: {
            padding: '20px',
            maxWidth: '100%',
            overflowX: 'auto'
        },
        statsBar: {
            padding: '10px',
            backgroundColor: '#f5f5f5',
            marginBottom: '10px',
            borderRadius: '4px'
        }
    };

    const RetryNotification = ({ attempt }) => (
        <div style={{
            position: 'fixed',
            top: '20px',
            right: '20px',
            backgroundColor: '#fff3cd',
            border: '1px solid #ffeeba',
            borderRadius: '4px',
            padding: '15px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            zIndex: 1000,
            animation: 'fadeIn 0.3s ease-in'
        }}>
            <p style={{ margin: 0, color: '#856404' }}>
                {t("common:retrying_process")} ({attempt}/{MAX_RETRIES})
            </p>
        </div>
    );

    const styleSheet = document.createElement('style');
    styleSheet.textContent = `
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
    `;
    document.head.appendChild(styleSheet);

    const handleEmailSend = async () => {
        setIsEmailSending(true);
        try {
            const pricingListId = new URLSearchParams(window.location.search).get('ids');
            
            const response = await sendEmail({
                variables: {
                    pricingListId
                }
            });
    
            if (response.data?.sendPreviewEmail?.success) {
                dispatch(showInfo(response.data.sendPreviewEmail.message || t("common:email_sent_successfully")));
            } else {
                throw new Error(response.data?.sendPreviewEmail?.message || 'Failed to send email');
            }
        } catch (err) {
            console.error('Email sending error:', err);
            dispatch(showError(err.message || t("common:email_send_failed")));
        } finally {
            setIsEmailSending(false);
        }
    };

    const handleRetry = () => {
        if (retryCount < MAX_RETRIES) {
            setRetryCount(prev => prev + 1);
            setIsRetrying(true);
            console.log(`Retrying export (attempt ${retryCount + 1}/${MAX_RETRIES})`);
            
            // Get the current URL parameters again
            const params = new URLSearchParams(window.location.search);
            const variables = {
                ids: params.get('ids')?.split(',').filter(Boolean) || [],
                targets: params.get('targets')?.split(',').filter(Boolean) || [],
                exportAsCorrection: params.get('exportAsCorrection') === 'true',
                marketingList: params.get('marketingList') || null,
                allocationList: params.get('allocationList') || null,
                minListIds: params.get('minListIds')?.split(',').filter(Boolean) || [],
                minValidFrom: params.get('minValidFrom') || null,
                minValidUntil: params.get('minValidUntil') || null
            };
    
            setTimeout(() => {
                previewExport({ variables });
            }, 2000);
        } else {
            setError(`Export failed after ${MAX_RETRIES} attempts`);
            setLoading(false);
            setIsRetrying(false);
        }
    };

    const [previewExport] = useMutation(PREVIEW_PRICING_LISTS, {
        onCompleted: (data) => {
            console.log('Preview data:', data);
            setIsRetrying(false);
    
            if (!data?.previewPricingListExport?.content) {
                console.error('No content received from server');
                if (retryCount < MAX_RETRIES) {
                    handleRetry();
                } else {
                    // Notify parent window of failure after max retries
                    window.opener?.postMessage({
                        type: 'PREVIEW_FAILED',
                        pricingListId: new URLSearchParams(window.location.search).get('ids'),
                        error: 'No content received after max retries'
                    }, '*');
                }
                return;
            }
    
            if (!validateExportData(data.previewPricingListExport.content)) {
                console.error('Invalid content structure');
                if (retryCount < MAX_RETRIES) {
                    handleRetry();
                } else {
                    // Notify parent window of failure after max retries
                    window.opener?.postMessage({
                        type: 'PREVIEW_FAILED',
                        pricingListId: new URLSearchParams(window.location.search).get('ids'),
                        error: 'Invalid content structure after max retries'
                    }, '*');
                }
                return;
            }
    
            setFileContent(data.previewPricingListExport);
            setLoading(false);
    
            // Notify parent window of success
            window.opener?.postMessage({
                type: 'PREVIEW_COMPLETE',
                pricingListId: new URLSearchParams(window.location.search).get('ids')
            }, '*');
        },
        onError: (error) => {
            console.error('Preview error:', error);
            if (retryCount < MAX_RETRIES) {
                handleRetry();
            } else {
                // Notify parent window of failure after max retries
                window.opener?.postMessage({
                    type: 'PREVIEW_FAILED',
                    pricingListId: new URLSearchParams(window.location.search).get('ids'),
                    error: error.message
                }, '*');
            }
        }
    });

    // First useEffect for styles
    useEffect(() => {
        const styleSheet = document.createElement('style');
        styleSheet.textContent = `
            @keyframes fadeIn {
                from { opacity: 0; transform: translateY(-20px); }
                to { opacity: 1; transform: translateY(0); }
            }
            @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
            }
        `;
        document.head.appendChild(styleSheet);
        return () => document.head.removeChild(styleSheet);
    }, []);

    // Second useEffect for data fetching
    useEffect(() => {
        const fetchPreview = async () => {
            try {
                const params = new URLSearchParams(window.location.search);
                
                const variables = {
                    ids: [params.get('ids')], // Single ID
                    targets: params.get('targets')?.split(',') || [],
                    exportAsCorrection: params.get('exportAsCorrection') === 'true',
                    marketingList: params.get('marketingList') || null,
                    allocationList: params.get('allocationList') || null,
                    minListIds: params.get('minListIds')?.split(',').filter(Boolean) || [],
                    minValidFrom: params.get('minValidFrom') || null,
                    minValidUntil: params.get('minValidUntil') || null
                };
    
                console.log('Starting preview fetch for:', variables.ids[0]);
                
                const result = await previewExport({ 
                    variables,
                    context: {
                        timeout: 300000 // 5 minutes
                    }
                });
                
                if (result.data?.previewPricingListExport?.content) {
                    const content = result.data.previewPricingListExport;
                    console.log(`Received content with length: ${content.content.length}`);
                    
                    // Process content
                    const processedLines = processContent(content.content);
                    
                    if (!processedLines || processedLines.length < 3) {
                        throw new Error('Incomplete content received');
                    }
    
                    setFileContent({
                        ...content,
                        lines: processedLines
                    });
                    setLoading(false);
                } else {
                    throw new Error('No preview data received');
                }
            } catch (error) {
                console.error('Preview error:', error);
                
                if (retryCount < MAX_RETRIES) {
                    setRetryCount(prev => prev + 1);
                    setTimeout(fetchPreview, 2000);
                } else {
                    setError(error.message);
                    setLoading(false);
                }
            }
        };
    
        fetchPreview();
    }, [previewExport, retryCount, processContent, t, dispatch]);

    // Render content in chunks
    const renderContent = () => {
        if (!fileContent?.lines) return null;

        return (
            <div className={styles.contentContainer}>
                <div className={styles.statsBar}>
                    <span>Total Lines: {contentStats.totalLines}</span>
                </div>
                <SynchronizedPreview 
                    content={fileContent.lines.join('\n')}
                    totalLines={contentStats.totalLines}
                />
            </div>
        );
    };    

    const handleDownload = () => {
        if (!fileContent || !fileContent.content) {
            setError('No content available for download');
            return;
        }

        try {
            const blob = new Blob([fileContent.content], { 
                type: fileContent.fileType 
            });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileContent.fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (err) {
            console.error('Download error:', err);
            setError('Failed to download file');
        }
    };

    const getLineCount = (content) => {
        return content ? content.split('\n').length : 0;
    };


    if (loading) {
        return (
            <div>
                <Banner text={t("common:processing_export")} />
                {isRetrying && <RetryNotification attempt={retryCount} />}
                <div style={loaderStyles.loader}>
                    <div style={loaderStyles.spinner} />
                    <h2>{t("common:export_in_progress")}</h2>
                    <p>{t("common:please_wait")}</p>
                    {isRetrying && (
                        <p style={{ 
                            color: '#856404', 
                            backgroundColor: '#fff3cd',
                            padding: '10px',
                            borderRadius: '4px',
                            marginTop: '10px'
                        }}>
                            {t("common:retrying_process")} ({retryCount}/{MAX_RETRIES})
                        </p>
                    )}
                    <p style={{
                        marginTop: '10px',
                        color: '#666'
                    }}>
                        {t("common:processing_pricelist", { 
                            id: new URLSearchParams(window.location.search).get('ids')
                        })}
                    </p>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div>
                <Banner text={t("common:export_error")} />
                <div style={{ padding: '20px' }}>
                    <h2>{t("common:export_failed")}</h2>
                    <p style={{ color: 'red' }}>Error: {error}</p>
                    <button 
                        className={buttonStyle.button}
                        onClick={() => window.close()}
                    >
                        {t("common:close")}
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div>
            <Banner text={t("common:export_preview")} />
            <div style={{ padding: '20px' }}>
                <h2>{t("common:file_preview")}</h2>
                
                {fileContent && (
                    <div style={{ marginTop: '20px' }}>
                        <h3>{t("common:file_details")}:</h3>
                        <div style={{ 
                            backgroundColor: '#f5f5f5', 
                            padding: '15px', 
                            borderRadius: '4px',
                            marginBottom: '20px'
                        }}>
                            <p><strong>{t("common:file_name")}:</strong> {fileContent.fileName}</p>
                            <p><strong>{t("common:file_type")}:</strong> {fileContent.fileType}</p>
                            <p><strong>{t("common:line_count")}:</strong> {getLineCount(fileContent.content)} lines</p>
                        </div>
    
                        <h3>{t("common:content_preview")}:</h3>
                        <SynchronizedPreview 
                            content={fileContent.content} 
                            onError={(error) => {
                                console.error('Preview error:', error);
                                dispatch(showError(t("common:preview_error")));
                            }}
                        />
                        
                        <div style={{ 
                            marginTop: '20px',
                            display: 'flex',
                            gap: '10px'
                        }}>
                            <button
                                className={buttonStyle.button}
                                onClick={() => window.close()}
                            >
                                {t("common:close")}
                            </button>
                            <button
                                className={buttonStyle.button}
                                onClick={handleDownload}
                            >
                                {t("common:download_export")}
                            </button>

                            <button
                                className={buttonStyle.button}
                                onClick={handleEmailSend}
                                disabled={!fileContent || isEmailSending}
                            >
                                {isEmailSending ? t("common:sending") : t("common:send_email")}
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default NewTabComponent;